import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import Moment from 'moment';

import { Table, Select, Button, Space, notification, Popconfirm, Typography, Row, Col, Collapse, Modal } from 'antd';
import { InfoCircleOutlined, DoubleLeftOutlined, PlusOutlined, RetweetOutlined, EditOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';

import languageManager from '../../../services/languageManager';

import * as storeActions from './store/actions/brands';

import Loader from '../../common/PageElements/Loader';
import TableFilters from '../../common/components/TableFilters';
import DrawerEditBrand from './components/DrawerEditBrand';

class BrandsList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      itemList: [],
      searchText: '',
      searchedColumn: '',
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      filters: [],
      filtersValues: [],
      appliedFilters: [],
      createDrawerItem: {},
      showCreateDrawer: false,
   };

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         // this.getComplements()
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }

   getList = (paginationParams, filtersValues) => {
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filtersValues)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ showCreateDrawer: false })
   }
   handleCreateOrUpdateBrand = (data) => {
      console.log('Brand creato/aggiornato correttamente')
      this.setState({ showCreateDrawer: false })
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filtersValues),
      ]).then()
   }

   deleteItemHandler = (itemId) => {
      this.setState({ pageLoader: true });
      storeActions.deleteItem(itemId, true)
         .then(response => {
            console.log(response);
            try {
               this.setState({ itemList: response.data, pageLoader: false });
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.confirm.deleteOk'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            } catch (e) {
               notification.open({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
                  icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
               })
            }

         }).catch((e) => {
            notification.open({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               icon: <InfoCircleOutlined style={{ color: '#108ee9' }} />,
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.setState({ sortedInfo: sorter });
      this.getList(paginationParams, this.state.filtersValues);
   };

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title } = Typography;
      const { Option } = Select;   
      const { Panel } = Collapse

      const table_columns = [
         // { title: 'Codice', width: 100, dataIndex: 'code', key: 'code', fixed: 'left' },
         { title: 'Nome', width: 100, dataIndex: 'name', key: 'name', fixed: 'left' },
         // {
         //    title: 'Visibile', width: 100, dataIndex: 'visible', key: 'visible', fixed: 'center',
         //    render: (text, record) => (
         //       <span>
         //          <a onClick={() => this.toggleActiveHandler(record.id)}>{record.visible ? <CheckOutlined twoToneColor="green" /> : <CloseOutlined twoToneColor="#red" />}</a>
         //       </span>),
         // },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            width: 100,
            render: (text, record) => (
               <Space>
                  <Button type="primary" size='small' title='Modifica'
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record)}
                  />
                  {/* <Button type="danger" size='small' title='Elimina'
                     icon={<DeleteOutlined />}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'common.delete_item'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askDeleteConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  /> */}
               </Space>),
         },
      ];

      // const pageContent = (
      //    <div className="card">
      //       <div className="card-header">
      //          <Row gutter={16} align='middle'>
      //             <Col>
      //                <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.brands.title')}</Title>
      //             </Col>
      //             <Col flex='auto'></Col>
      //             <Col>
      //                <Space>
      //                   <Link to={{ pathname: "/brands/edit/" }} >
      //                      <Button
      //                         type='primary'
      //                         icon={<PlusSquareOutlined />}
      //                      >{languageManager.getMessage(this.props.commonState, 'component.brands.add_brand')}</Button>
      //                   </Link>
      //                   <Select
      //                      showSearch
      //                      className="width-70px-I"
      //                      placeholder="Seleziona la lingua"
      //                      defaultValue={this.state.componentLanguage}
      //                      optionFilterProp="children"
      //                      onChange={this.setDefaultLanguageHandler}
      //                   //  onFocus={onFocus}
      //                   //  onBlur={onBlur}
      //                   //  onSearch={onSearch}
      //                   //  filterOption={(input, option) =>
      //                   //    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      //                   //  }
      //                   >
      //                      {
      //                         this.props.authState.userData.languages.map((lang) => {
      //                            return <Option key={lang.value} value={lang.value}>{lang.label}</Option>
      //                         })
      //                      }
      //                   </Select>
      //                </Space>
      //             </Col>
      //          </Row>
      //       </div>
      //       <div className="card-body">
      //          <Table {...tableLayout} columns={table_columns} dataSource={this.state.itemList} scroll={{ x: 1000 }} />
      //       </div>
      //       <div className="card-footer">
      //          <Row gutter={16}>
      //             <Col>
      //                <Space size='small'>
      //                   <Link to={{ pathname: "/" }} >
      //                      <Button icon={<DoubleLeftOutlined />}>
      //                         {languageManager.getMessage(this.props.commonState, 'common.back')}
      //                      </Button>
      //                   </Link>
      //                </Space>
      //             </Col>
      //             <Col flex='auto'></Col>
      //             <Col></Col>
      //          </Row>
      //       </div>
      //    </div>
      // );

      
      const tableLayout = {
         bordered: true,
         // ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            current: this.state.paginationParams.current_page,
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' brands' : ' brand')
         },
         onChange: this.handleTableChange,
      }

      const brandsTable = (
         <Table
            {...tableLayout}
            columns={table_columns}
            rowKey={(record) => (record.id)}
            dataSource={this.state.itemList}
         />
      )

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const drawerEditBrand = (
         <DrawerEditBrand
            key='createDrawer'
            visible={this.state.showCreateDrawer}
            item={this.state.createDrawerItem}
            complements={{ ...this.state.complements }}
            onClose={this.hideCreateDrawer}
            onSubmit={(data) => this.handleCreateOrUpdateBrand(data)} />
      )

      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={() => this.getList(this.state.paginationParams, this.state.filtersValues)}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.brands.new')}
                        </Button>
                     </Space>
                  </div>
               </div>
            </div>
            {brandsTable}
            {drawerEditBrand}
         </div >
      )
      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.brands.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               {/* <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row> */}
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

export default connect(mapStateToProps)(BrandsList);
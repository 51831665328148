import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import Moment from 'moment'
import 'moment-timezone'

import { Table, Select, Button, Space, notification, Divider, Card, Collapse, Row, Col, Typography, List, Modal } from 'antd'
// import Highlighter from 'react-highlight-words';
import { PlusOutlined, EditOutlined, DeleteOutlined, CloseOutlined, ImportOutlined, UnorderedListOutlined, QuestionCircleOutlined, DoubleLeftOutlined, RetweetOutlined } from '@ant-design/icons'

import languageManager from '../../../services/languageManager'

import * as storeActions from './store/actions/warehouses'

import { startLoading, endLoading } from '../../../shared/utility'
import TableFilters from '../../common/components/TableFilters'
import DrawerEditWarehouse from './components/DrawerEditWarehouse'
import DrawerImportStocks from './components/DrawerImportStocks'

import Loader from '../../common/PageElements/Loader'
import DropdownMenu from '../../common/components/DropdownMenu'

class WarehousesList extends Component {
   state = {
      pageLoader: true,
      tableLoader: false,
      importLoader: false,
      loadersDelete: [],
      loadersEmpty: [],
      loadersBrandEmpty: [],
      itemList: [],
      fullListIds: [],
      complements: {},
      componentLanguage: this.props.authState.defaultLanguage,
      paginationParams: {
         current_page: 1,
         per_page: 50,
         total: 0,
      },
      selectedRowKeys: [],
      selectedAll: false,
      expandedRowKeys: [],
      expandedAll: false,
      multipleActionLoader: false,
      multipleActionList: [
         {
            action: 'empty',
            name: 'Svuota magazzini selezionati',
            needconfirmation: 'yes',
         },
      ],
      multipleExportLoader: false,
      multipleExportList: [
         {
            action: 'export_warehouses',
            name: 'Esporta magazzini selezionati',
            needconfirmation: 'no',
         },
         {
            action: 'export_warehouses_union',
            name: 'Esporta magazzini selezionati (cumulativo)',
            needconfirmation: 'no',
         },
      ],
      feedback: {},
      searchOrderInput: null,

      filters: [],
      // filtersValues: {},
      appliedFilters: [],
   };

   getList = (paginationParams, filters) => {
      // asyncronous calls
      console.log(filters);
      this.setState({ tableLoader: true })
      return storeActions.getListExtended(paginationParams, filters)
         .then(response => {
            console.log(response);
            try {
               let updatedFilters = []
               for (let filter of response.list_filters) {
                  if (filter.type === 'date') {
                     if (filter.value) {
                        filter.value = Moment(filter.value, 'YYYY-MM-DD');
                     }
                  }
                  updatedFilters.push(filter);
               }

               this.setState({
                  itemList: response.data.data,
                  fullListIds: response.full_list_ids,
                  paginationParams: {
                     current_page: response.data.current_page,
                     per_page: response.data.per_page,
                     total: response.data.total
                  },

                  filters: updatedFilters,
                  appliedFilters: response.applied_filters,

                  tableLoader: false,
               });
            } catch (e) {
               this.setState({ tableLoader: false })
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            this.setState({ tableLoader: false })
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   getComplements = () => {
      return storeActions.getComplements()
         .then(response => {
            console.log(response);
            try {
               const complements = {
                  brandsList: response.brandsList,
                  warehousesList: response.warehousesList,
               }
               this.setState({
                  complementsResponse: response,
                  complements: complements
               });
            } catch (e) {
               notification.error({
                  message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
                  description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
               })
               if (this.props.commonState.debug) console.log(e);
            }
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   componentDidMount() {
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         this.getComplements(),
      ]).then(() => {
         this.setState({
            pageLoader: false
         });
      });
   }
   updateList = () => {
      this.getList(this.state.paginationParams, this.state.filtersValues)
   }

   expandAllHandler = () => {
      var allIds = _.clone(this.state.fullListIds)
      this.setState({
         expandedAll: true,
         expandedRowKeys: allIds,
      });
   }
   expandNoneHandler = () => {
      this.setState({
         expandedAll: false,
         expandedRowKeys: [],
      });
   }
   onExpandChange = (expanded, record) => {
      var expandedRows = _.clone(this.state.expandedRowKeys)
      if (expanded) {
         expandedRows.push(record.id)
      } else {
         _.pull(expandedRows, record.id)
      }
      this.setState({
         expandedAll: _.isEqual(_.sortBy(expandedRows), _.sortBy(this.state.fullListIds)),
         expandedRowKeys: expandedRows,
      });
   }
   onSelectChange = (selectedRowKeys) => {
      this.setState({ selectedRowKeys: selectedRowKeys });
   };
   selectAllHandler = () => {
      if (this.state.selectedAll) {
         this.setState({
            selectedAll: false,
            selectedRowKeys: [],
         });
      }
      else {
         this.setState({
            selectedAll: true,
            selectedRowKeys: this.state.fullListIds,
         });
      }
   }
   multipleActionHandler = (value) => {
      const props = value.item.props
      switch (props.action) {
         case 'empty':
            this.emptyItemsHandler(this.state.selectedRowKeys);
            break
         case 'export_warehouses':
            this.exportWarehousesHandler(this.state.selectedRowKeys);
            break
         case 'export_warehouses_union':
            this.exportWarehousesHandler(this.state.selectedRowKeys, true);
            break
         default:
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: languageManager.getMessage(this.props.commonState, 'notification.error.clientError'),
            })
            break
      }
   }

   handleTableChange = (pagination, filters, sorter, extra) => {
      console.log(pagination, filters, sorter, extra);
      const paginationParams = {
         current_page: pagination.current,
         per_page: pagination.pageSize,
         order_column: !_.isNil(sorter.column) && !_.isNil(sorter.column.key) ? sorter.column.key : null,
         order_direction: !_.isNil(sorter.order) ? sorter.order : null,
      }
      this.getList(paginationParams, this.state.filtersValues);
   }
   submitFilters = (values) => {
      Object.keys(values).forEach(function (key) {
         if (_.isArray(values[key])) {
            _.forEach(values[key], function (value, key, array) {
               if (Moment.isMoment(value)) {
                  array[key] = Moment(value).format('YYYY-MM-DD');
               }
            })
         } else if (Moment.isMoment(values[key])) {
            values[key] = Moment(values[key]).format('YYYY-MM-DD');
         }
      });
      this.setState({ filtersValues: values, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, values);
   }
   resetFilters = () => {
      this.setState({ filtersValues: {}, selectedRowKeys: [] })
      this.getList(this.state.paginationParams, {});
   }

   editItemHandler = item => {
      this.setState({ createDrawerItem: item, showCreateDrawer: true })
   }
   hideCreateDrawer = () => {
      this.setState({ showCreateDrawer: false })
      // this.setState({ createDrawerItem: null, })
   }
   handleCreateOrUpdateWarehouse = (data) => {
      console.log('Warehouse creato/aggiornato correttamente')
      this.setState({ showCreateDrawer: false })
      Promise.all([
         this.getList(this.state.paginationParams, this.state.filters),
         // this.getComplements()
      ]).then()
   }

   deleteItemHandler = itemId => {
      this.deleteItemsHandler([itemId])
   }
   deleteItemsHandler = (itemIds) => {
      this.setState({ loadersDelete: startLoading(this.state.loadersDelete, itemIds) })
      return storeActions.deleteItems(itemIds, false)
         .then(response => {
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
               duration: 0
            })
            Promise.all([
               this.getList(this.state.paginationParams, this.state.filtersValues),
            ]).then(() => {
               this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersDelete: endLoading(this.state.loadersDelete, itemIds) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   emptyItemHandler = itemId => {
      this.emptyItemsHandler([itemId])
   }
   emptyItemsHandler = (itemIds) => {
      this.setState({ loadersEmpty: startLoading(this.state.loadersEmpty, itemIds) })
      return storeActions.emptyItems(itemIds, false)
         .then(response => {
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
               duration: 0
            })
            Promise.all([
               this.getList(this.state.paginationParams, this.state.filtersValues),
            ]).then(() => {
               this.setState({ loadersEmpty: endLoading(this.state.loadersEmpty, itemIds) })
            });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersEmpty: endLoading(this.state.loadersEmpty, itemIds) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   emptyBrandHandler = (warehouseId, brandId) => {
      this.setState({ loadersEmpty: startLoading(this.state.loadersEmpty, warehouseId) })
      return storeActions.emptyBrandItems(warehouseId, brandId, false)
         .then(response => {
            notification.success({
               message: languageManager.getMessage(this.props.commonState, 'notification.success.title'),
               description: response.human ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={response.human}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.confirm.operationOk'),
               duration: 0
            })
            Promise.all([
               this.getList(this.state.paginationParams, this.state.filtersValues),
            ]).then(() => {
               this.setState({ loadersEmpty: endLoading(this.state.loadersEmpty, warehouseId) })
            });
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ loadersEmpty: endLoading(this.state.loadersEmpty, warehouseId) })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   exportWarehousesHandler = (itemIds, union = false) => {
      this.setState({ multipleExportLoader: true })
      const filename = Moment().format('YYYY.MM.DD-HH.mm.ss') + '_STOCKS_WAREHOUSES_EXPORT.xlsx';
      return storeActions.exportStocksWarehouses(itemIds, filename, union)
         .then(response => {
            this.setState({ multipleExportLoader: false })
         }).catch((e) => {
            notification.error({
               message: languageManager.getMessage(this.props.commonState, 'notification.warning.title'),
               description: e.error ? (
                  <List
                     size="small"
                     split={false}
                     dataSource={e.error}
                     renderItem={item => <List.Item>{item}</List.Item>}
                  />
               ) : languageManager.getMessage(this.props.commonState, 'notification.error.serverError'),
               duration: 0
            })
            this.setState({ multipleExportLoader: false })
            if (this.props.commonState.debug) console.log(e);
         });
   }

   showImportDrawer = (show) => {
      this.setState({ showImportDrawer: show })
   }

   render() {
      if (this.state.pageLoader) return <Loader />
      const { Title, Text } = Typography
      const { Panel } = Collapse
      const { Option } = Select
      const { selectedRowKeys } = this.state;
      const table_columns = [
         {
            title: 'Codice',
            width: 100,
            key: 'code',
            fixed: 'left',
            render: (record) => (
               record.code
            ),
            sorter: true,
         },
         {
            title: 'Magazzino',
            width: 300,
            key: 'name',
            fixed: 'left',
            render: (record) => (
               record.name
            ),
            sorter: true,
         },
         {
            title: 'Totale prodotti',
            width: 150,
            key: 'total_products',
            render: (record) => (
               record.total_products
            ),
         },
         {
            title: 'Quantità',
            width: 150,
            key: 'total_quantity',
            render: (record) => (
               record.total_quantity
            ),
         },
         {
            title: 'Totale valore (WHLS)',
            width: 150,
            key: 'total_value',
            render: (record) => (
               record.total_value_human
            ),
         },
         {
            title: 'Totale costo',
            width: 150,
            key: 'total_cost',
            render: (record) => (
               record.total_cost_human
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  <Link to={{ pathname: '/warehouses/stocks/' + record.id }}>
                     <Button type="success" size='small'
                        icon={<UnorderedListOutlined />}
                     />
                  </Link>
                  <Button type="primary" size='small'
                     icon={<EditOutlined />}
                     onClick={() => this.editItemHandler(record)}
                  />
                  <Button type="danger" size='small' title={languageManager.getMessage(this.props.commonState, 'component.warehouses.empty')}
                     icon={<CloseOutlined />}
                     loading={this.state.loadersEmpty[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.warehouses.empty'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.emptyItemHandler(record.id)
                        })
                     }
                  />
                  <Button type="danger" size='small' title={languageManager.getMessage(this.props.commonState, 'common.delete')}
                     icon={<DeleteOutlined />}
                     loading={this.state.loadersDelete[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.warehouses.delete'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.deleteItemHandler(record.id)
                        })
                     }
                  />
               </Space>),
         },
      ];
      const tableLayout = {
         bordered: true,
         ellipsis: true,
         size: "small",
         showSorterTooltip: false,
         loading: this.state.tableLoader,
         pagination: {
            pageSize: this.state.paginationParams.per_page,
            total: this.state.paginationParams.total,
            showSizeChanger: true,
            defaultPageSize: 50,
            size: "default",
            showTotal: total => total + (total > 1 ? ' magazzini' : ' magazzino')
         },
         onChange: this.handleTableChange,
         columns: table_columns,
         rowSelection: {
            selectedRowKeys,
            onChange: this.onSelectChange,
            preserveSelectedRowKeys: true,
         },
         rowKey: (record) => (record.id),
         dataSource: this.state.itemList,
         expandable: {
            indentSize: 30,
            expandedRowKeys: this.state.expandedRowKeys,
            onExpand: this.onExpandChange,
            expandedRowRender: record => (
               <Space direction='vertical' size='small'>
                  <Table {...tableBrandsLayout}
                     columns={tableBrandsColumns}
                     dataSource={record.brands}
                  />
                  <Divider />
               </Space>
            )
         }
      }
      const tableBrandsLayout = {
         bordered: true,
         size: "small",
         pagination: false,
         loading: this.state.loaderBrandStats,
      }
      const tableBrandsColumns = [
         {
            title: 'Brand',
            key: 'brand',
            width: 125,
            align: 'left',
            render: (record) => (
               record.brand
            ),
         },
         {
            title: 'Totale prodotti',
            width: 150,
            key: 'total_products',
            render: (record) => (
               record.total_products
            ),
         },
         {
            title: 'Quantità',
            width: 150,
            key: 'total_quantity',
            render: (record) => (
               record.total_quantity
            ),
         },
         {
            title: 'Totale valore (WHLS)',
            width: 150,
            key: 'total_value',
            render: (record) => (
               record.total_value_human
            ),
         },
         {
            title: 'Totale costo',
            width: 150,
            key: 'total_cost',
            render: (record) => (
               record.total_cost_human
            ),
         },
         {
            title: 'Azioni',
            key: 'actions',
            fixed: 'right',
            align: 'right',
            render: (text, record) => (
               <Space>
                  {/* <Link to={{ pathname: '/unique_files/ria/details/' + record.unique_file_id + '/' + record.id }}>
                     <Button type="success" size='small'
                        icon={<EyeOutlined />}
                     />
                  </Link> */}
                  <Button type="danger" size='small' title={languageManager.getMessage(this.props.commonState, 'component.warehouses.empty_brand')}
                     icon={<CloseOutlined />}
                     loading={this.state.loadersBrandEmpty[record.id]}
                     onClick={() =>
                        Modal.confirm({
                           title: languageManager.getMessage(this.props.commonState, 'component.warehouses.empty_brand'),
                           icon: <QuestionCircleOutlined />,
                           content: languageManager.getMessage(this.props.commonState, 'common.askOperationConfirm'),
                           okText: languageManager.getMessage(this.props.commonState, 'common.confirm'),
                           onOk: () => this.emptyBrandHandler(record.warehouse_id, record.id)
                        })
                     }
                  />
               </Space>),
         },
      ]

      const pageFilters = (
         <Collapse defaultActiveKey={['filters']}>
            <Panel header="Filtri" key="filters">
               <TableFilters submitFilters={this.submitFilters} resetFilters={this.resetFilters} filters={this.state.filters} />
            </Panel>
         </Collapse>
      );

      const drawerEditWarehouse = (
         <DrawerEditWarehouse
            key='createDrawer'
            visible={this.state.showCreateDrawer}
            item={this.state.createDrawerItem}
            complements={{ ...this.state.complements }}
            onClose={this.hideCreateDrawer}
            onSubmit={(data) => this.handleCreateOrUpdateWarehouse(data)} />
      )
      const drawerImportStocks = (
         <DrawerImportStocks
            key='importDrawer'
            visible={this.state.showImportDrawer}
            complements={{ ...this.state.complements }}
            onSubmit={this.updateList}
            onClose={() => this.showImportDrawer(false)} />
      )

      const hasSelected = selectedRowKeys.length > 0;
      const singleSelected = selectedRowKeys.length > 1;
      const pageContent = (
         <div>
            <div className="align-items-center row" style={{ marginTop: 15, marginBottom: 5 }}>
               <div className="col-sm-6">
                  <div className="d-none d-md-block">
                     <Space>
                        {
                           !this.state.expandedAll ? (
                              <Button
                                 onClick={() => this.expandAllHandler()}
                              >Espandi tutto
                              </Button>
                           ) : null
                        }
                        {
                           !_.isEmpty(this.state.expandedRowKeys) ? (
                              <Button
                                 onClick={() => this.expandNoneHandler()}
                              >Collassa tutto
                              </Button>
                           ) : null
                        }
                        <Button
                           onClick={() => this.selectAllHandler()}
                        >{this.state.selectedAll ? 'Deseleziona' : 'Seleziona tutto'}
                        </Button>
                        <span>
                           {hasSelected ? singleSelected ? `${selectedRowKeys.length} elementi selezionati` : `${selectedRowKeys.length} elemento selezionato` : ''}
                        </span>
                        <DropdownMenu
                           type='primary'
                           title='Azioni multiple'
                           action={this.multipleActionHandler}
                           values={this.state.multipleActionList}
                           disabled={!hasSelected}
                           loading={this.state.multipleActionLoader}
                        />
                        <DropdownMenu
                           type='primary'
                           title='Esporta'
                           action={this.multipleActionHandler}
                           values={this.state.multipleExportList}
                           disabled={!hasSelected}
                           loading={this.state.multipleExportLoader}
                        />
                     </Space>
                  </div>
               </div>
               <div className="col-sm-6">
                  <div className="float-right d-none d-md-block">
                     <Space>
                        <Button icon={<RetweetOutlined />} onClick={() => this.getList(this.state.paginationParams, this.state.filters)}>
                           {languageManager.getMessage(this.props.commonState, 'common.refresh_data')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<ImportOutlined />}
                           onClick={() => this.showImportDrawer(true)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.stock_warehouses.import')}
                        </Button>
                        <Button
                           type='primary'
                           icon={<PlusOutlined />}
                           onClick={() => this.editItemHandler(null)}
                        >
                           {languageManager.getMessage(this.props.commonState, 'component.warehouses.new')}
                        </Button>
                     </Space>
                  </div>
               </div>
            </div>
            <Table {...tableLayout} />
            {drawerEditWarehouse}
            {drawerImportStocks}
         </div >
      )

      const pageWrapper = (
         <div className="card">
            <div className="card-header">
               <Row gutter={16} align='middle'>
                  <Col>
                     <Title level={5}>{languageManager.getMessage(this.props.commonState, 'component.warehouses.title')}</Title>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col>
                     <Space>
                     </Space>
                  </Col>
               </Row>
            </div>
            <div className="card-body">
               <Row>
                  <Col span={24}>{pageFilters}</Col>
               </Row>
               {pageContent}
            </div>
            <div className="card-footer">
               <Row gutter={16}>
                  <Col>
                     <Space size='small'>
                        <Link to={{ pathname: "/" }} >
                           <Button icon={<DoubleLeftOutlined />}>
                              {languageManager.getMessage(this.props.commonState, 'common.back')}
                           </Button>
                        </Link>
                     </Space>
                  </Col>
                  <Col flex='auto'></Col>
                  <Col></Col>
               </Row>
            </div>
         </div>
      );

      return (
         <div className="container-fluid">
            {pageWrapper}
         </div>
      );
   }
}

const mapStateToProps = state => {
   return {
      authState: state.authenticationState,
      commonState: state.commonState,
   };
};

// const mapDispatchToProps = dispatch => {
//     return {
//         onFetchTaxRates: () => dispatch( actions.getTaxRatesList() )

//     };
// };

export default connect(mapStateToProps)(WarehousesList);
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import languageManager from '../../../../services/languageManager'
import * as purchaseOrdersActions from '../../../orders/purchase_orders/store/actions/purchase_orders'
import { PurchaseOrderTypeEnum, ProgressiveQuarterEnum } from '../../../../shared/enums'

import { Form, Input, InputNumber, Select, Button, Row, Col, notification, List, Space, Typography } from 'antd'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'

const FormEditBrand = (props) => {
    const [layout, setLayout] = useState(props.layout)
    // const [customerId, setCustomerId] = useState(props.customerId)
    const [values, setValues] = useState(props.values)
    const [complements, setComplements] = useState(props.complements)

    const [form] = Form.useForm()
    const { Text } = Typography
    const { TextArea } = Input;
    const { Option } = Select

    useEffect(() => {
        console.log(props)
        // setCustomerId(props.customerId)
        setValues(props.values)
        if (_.isEmpty(props.values)) {
            form.resetFields()
        } else {
            form.setFieldsValue(props.values)
        }
    }, [props.values])

    const submitForm = (values) => {
        console.log('submit form', values)
        // values.customer_id = customerId
        props.submitForm(values)
    }

    return (
        <Form
            form={form}
            {...layout}
            id="FormEditBrand"
            onFinish={submitForm}
        >
            <Form.Item
                label={languageManager.getMessage(props.commonState, 'component.label.brand')}
                name="name"
            >
                <Input />
            </Form.Item>
            <Form.Item name="id" noStyle><Input type="hidden" /></Form.Item>
        </Form >
    )
}

const mapStateToProps = state => {
    return {
        commonState: state.commonState,
    }
}

export default connect(mapStateToProps)(FormEditBrand)